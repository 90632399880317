import { getAllBlockchainConfigs } from "./blockchain";
import { ClientConfig } from "./types";

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const publicConfig: ClientConfig = {
  url: process.env.NEXT_PUBLIC_URL!,
  webEnv: process.env.NEXT_PUBLIC_WEB_ENV!,
  apiUrl: process.env.NEXT_PUBLIC_API_URL!,
  tokenPageUrl: process.env.NEXT_PUBLIC_TOKEN_PAGE_URL,
  ipfsGateway: process.env.NEXT_PUBLIC_IPFS_GATEWAY!,
  walletConnectProjectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID!,
  coinbaseAppName: process.env.NEXT_PUBLIC_COINBASE_APP_NAME!,
  chains: getAllBlockchainConfigs(),
  defaultChain: process.env.NEXT_PUBLIC_DEFAULT_CHAIN!,
  availableChains: process.env.NEXT_PUBLIC_CHAINS!,
  telegramBotId: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID!,
  telegramBotName: process.env.NEXT_PUBLIC_TELEGRAM_BOT_NAME!,
  maintenance: process.env.NEXT_PUBLIC_MAINTENANCE! === "true",
  socialsOAuth: {
    instagram: `${process.env.NEXT_PUBLIC_API_URL!}/auth/instagram`,
    twitter: `${process.env.NEXT_PUBLIC_API_URL!}/auth/twitter`,
    facebook: `${process.env.NEXT_PUBLIC_API_URL!}/auth/facebook`,
    discord: `${process.env.NEXT_PUBLIC_API_URL!}/auth/discord`,
    telegram: `${process.env.NEXT_PUBLIC_API_URL!}/auth/telegram`,
  },

  endToken: {
    address: process.env.NEXT_PUBLIC_AURORA_END_TOKEN_ADDRESS!,
    symbol: process.env.NEXT_PUBLIC_AURORA_END_TOKEN_SYMBOL!,
    decimals: process.env.NEXT_PUBLIC_AURORA_END_TOKEN_DECIMALS!,
  },

  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN!,
    env: process.env.NEXT_PUBLIC_SENTRY_ENV!,
  },

  indexer: {
    httpUrl: process.env.NEXT_INDEXER_HTTP_URL!,
    wsUrl: process.env.NEXT_PUBLIC_INDEXER_WS_URL!,
  },

  cookies: {
    blockchain: process.env.NEXT_PUBLIC_BLOCKCHAIN_COOKIE_NAME!,
    token: process.env.NEXT_PUBLIC_TOKEN_COOKIE_NAME!,
    theme: process.env.NEXT_PUBLIC_THEME_COOKIE_NAME!,
  },
  lightGallery: {
    license: process.env.NEXT_PUBLIC_LIGHT_GALLERY_LICENSE!,
  },
  site: {
    title: "Endemic - Collect smart art",
    description:
      "Endemic is a platform connecting established artists and the real-world art with the newly growing NFT market.",
    ogImage: "https://endemic.app/assets/og.png",
    twitter_url: "https://twitter.com/Endemic_nft",
    instagram_url: "https://www.instagram.com/endemic.app/",
    discord_url: "https://discord.gg/xfavbpGEFj",
    telegram_url: "https://t.me/endemicapp",
    terms_of_use:
      "https://docs.google.com/document/d/1t1rNzZSfygSmunY7UFa5zfExYrvl-8RF/edit?usp=sharing&ouid=108923248931839640650&rtpof=true&sd=true",
    endemic_docs: "https://docs.endemic.app",
    metamask_url: "https://metamask.io/download/",
  },
  mintdash: {
    baseURI: process.env.NEXT_PUBLIC_MINTDASH_BASE_URI!,
    endemicIdentitySoulboundAddress:
      process.env.NEXT_PUBLIC_ENDEMIC_IDENTITY_SOULBOUND_ADDRESS!,
    endemicIdentitySoulboundNetwork:
      process.env.NEXT_PUBLIC_ENDEMIC_IDENTITY_SOULBOUND_NETWORK!,
    collectionId:
      process.env.NEXT_PUBLIC_ENDEMIC_IDENTITY_SOULBOUND_COLLECTION_ID!,
    chainId: process.env.NEXT_PUBLIC_ENDEMIC_IDENTITY_SOULBOUND_CHAIN_ID!,
  },
  google: {
    analyticsId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  },
  endemicUserId: process.env.NEXT_PUBLIC_ENDEMIC_USER_ID!,
};
